@import './pages/dashboard';
@import './components/tabList';

.h-100-overflow-h {
  height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
}

.font-icon-32 {
  font-size: 30px !important;
}

.icon-large-size {
  font-size: 65px;
}

.btnWidthMedium {
  width: 140px;
}

.entityList {
  .MuiGrid-item {
    @extend .text-large;
    @extend .font-weight-medium;
    @extend .textColor-200;
  }
}

//backArrow Card header
.backArrow {
  left: -7px;
  top: -5px;
  font-size: 24px;
}

//customChips
.customChips {
  .MuiChip-root {
    padding: 6px 10px !important;
    height: 24px;
    @extend .text-small;
    @extend .font-weight-medium;
    @include border-radius(40px);

    .MuiChip-label {
      margin-right: 0px !important;
      @extend .font-weight-semibold;
    }
  }
}

//textFeildPhoneNumber
.textFieldPhoneNumber {
  .MuiInputLabel-root {
    @extend .text-large;

    &.MuiInputLabel-shrink {
      -webkit-transform: translate(16px, 5px) scale(0.75) !important;
      -moz-transform: translate(16px, 5px) scale(0.75) !important;
      -ms-transform: translate(16px, 5px) scale(0.75) !important;
      transform: translate(16px, 5px) scale(0.75) !important;
    }
  }

  .MuiOutlinedInput-root {
    padding: 19px 13px 0px 16px;
  }

  .MuiOutlinedInput-notchedOutline {
    top: -4px !important;
  }

  fieldset {
    legend {
      display: none !important;
    }
  }

  .MuiInputAdornment-root {
    .MuiButtonBase-root {
      svg {
        width: 30px !important;
      }
    }
  }
}

//mesg section scroll
.messageSectionScroll {
  overflow: auto;
  @include commonScrollbarBar;
}

.scrollBarCard {
  overflow: auto !important;
  height: calc(100vh - 77px);
  @include commonScrollbarBar;
}

.actionIconFont-24 {
  font-size: 24px;
}

.documentCardHeight {
  @extend .h-100-overflow-h;
  @extend .flex-basic-center;
  align-items: start !important;
  height: calc(100vh - 155px);

  .icon-document {
    font-size: 60px;
  }
}

//textFieldSearchWithIcon
.textFieldSearchWithIcon {
  .MuiFormControl-root {
    .Mui-focused {
      .icon-filter,
      .icon-search {
        color: $textColor-300 !important;
      }
    }
  }

  .MuiTextField-root {
    [class*='icon-'] {
      font-size: 1.25rem;
    }

    .icon-filter {
      padding-top: 2px;
    }
  }
}

//text Feild Icon
.infoIcon {
  @extend .p-absolute;
  right: 0px;
  top: 10px;
}

//width per clasess
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

//document section
.card-height-100 {
  height: calc(100vh - 158px);
  @include commonScrollbarBar;
}

.card-height-200 {
  height: 100vh;
  @include commonScrollbarBar;
}

.scrollBox {
  overflow-y: auto;
  height: calc(100vh - 278px);
  @include commonScrollbarBar;
}

.docListItems {
  @include commonScrollbarBar;

  .listItems {
    border-bottom: 1px solid $borderColor;

    &:last-child {
      border-bottom: none;
    }
  }
}

//userMessageList

.icon-messages-font {
  font-size: 60px;
}

.userMessageList {
  overflow-y: auto !important;
  .mb-4 {
    margin-bottom: 4px;
  }

  .borderBottom {
    border-bottom: 1px solid $borderColor;
  }

  .msgwithCountSection {
    .msgwithCount {
      @extend .p-absolute;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      font-size: 8px;
      color: $white !important;
      @extend .text-center;
      padding-top: 1px;
      right: -3px;
      top: 16px;
    }
  }
}

//msgTabsection
.msgTabsection {
  .tab-wrapper {
    padding: 0rem 0.8rem !important;
    z-index: 9999;

    .MuiTabScrollButton-root {
      display: none !important;
    }

    .MuiButtonBase-root {
      @extend .text-medium;
      @extend .font-weight-semibold;
      margin-right: 1.25rem;
    }
  }

  .documentTabData {
    .MuiPaper-root {
      padding-top: 0.5rem !important;
    }
  }

  .MuiPaper-root {
    &.MuiPaper-elevation {
      border: none !important;
    }
  }

  .MuiTabs-indicator {
    bottom: 16px !important;
  }
}

.textReplyArea {
  .replyButton {
    @extend .p-absolute;
    top: 40px;
    right: 13px;
  }
}

.text-font-20 {
  font-size: 20px;
}

.mailIcon {
  @extend .font-icon-32;
  @extend .p-absolute;
  left: 48% !important;
  top: 28% !important;
}

.icon-error-rotate {
  transform: rotate(180deg);
}

//brandingLogo about section
.brandingLogo {
  height: 171px;
  width: 171px;
  @include border-radius(10px);
}

.marginTopLess {
  margin-top: -20px;
}

.broken-icon {
  color: $white;
  font-size: 100px;
}

.imgBox {
  height: 32px;
  width: 32px;
  @include border-radius(50%);
}

.iconWithCount {
  @extend .p-relative;

  .countText {
    height: 16px;
    width: 16px;
    @include border-radius(50%);
    color: $white;
    right: -9px;
    top: -5px;
    @extend .p-absolute;

    &.count-invite {
      right: -15px !important;
      top: 11px !important;
      z-index: 999;
    }
  }
}

.width-200 {
  width: 200px;
}

//unreadCount badge
.unreadCountBox {
  border: 1px solid;
  padding: 1px 12px;
  @extend .text-medium;
  @extend .font-weight-semibold;
  @include border-radius(50px);
}

.documentTabData {
  margin-left: -24px;
  margin-top: -95px;
}

//Tcard box style
.MuiPaper-root {
  &.MuiPaper-elevation {
    &.MuiPaper-rounded,
    &.MuiPaper-elevation1 {
      border: 1px solid;
      box-shadow: none !important;
      border-color: $bgLightDarkColor !important;
      @include border-radius(10px);
    }
  }
}

//video camera switch icon
[class*='video-recorder__CameraView-'] {
  [class*='switch-camera-view__'] {
    display: none !important;
  }
}

//password
.passContentList {
  @extend .p-relative;
  padding: 2px 13px;

  &::before {
    @extend .p-absolute;
    content: '';
    height: 2px;
    width: 2px;
    background-color: $textColor-300;
    top: 10px;
    left: 5px;
    @include border-radius(50%);
  }
}

.almostThereSizes {
  width: 154px;
  height: 104px;
}

.userName {
  @extend .text-ellipsis;
  max-width: 16rem;
}

//custom error msg
.custom-error-msg-text {
  margin: 5px 1px;
  @extend .text-small;
  @extend .error-color-state;
}

.emptyCardHeight {
  height: calc(100vh - 152px);
}

.MuiDivider-root {
  &.MuiDivider-fullWidth {
    border-color: $borderColor;
  }
}

//invite flow img boxes
.img-radius-box {
  &.small-box {
    width: 32px;
    height: 32px;
  }

  &.medium-box {
    width: 72px;
    height: 72px;
  }

  &.large-box {
    width: 152px;
    height: 152px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.wrapperError {
  height: 32px;
  width: 32px;
  @include border-radius(50%);
  border: 1px solid;
  border-color: $state-denied;
}

.custom-progress-icon {
  span {
    top: 10px;
    left: 10px;
    @extend .p-absolute;
    font-size: 16px;
    color: $textColor-200;
  }
}

.circularIcon {
  span {
    color: $white;
    font-size: 41px;
  }
}

.makeStyles-confirmDialog-388 .MuiDialog-container .MuiPaper-root {
  width: 303px !important;
}

//popup css for user portal only
.MuiModal-root {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiBox-root {
        h2 {
          @extend .text-h3;
        }
      }

      .MuiDialogContent-root {
        @extend .text-medium;
      }

      .MuiDialogActions-root {
        padding: 0px 20px 20px 20px !important;
      }
    }
  }
}

//login or section
.loginOrSection {
  @extend .p-relative;

  &::before {
    width: 100%;
    height: 1px;
    background-color: $textColor-500;
    content: '';
    @extend .p-absolute;
    z-index: 999;
  }

  .orCircle {
    height: 40px;
    width: 40px;
    background-color: $backgroundColor;
    @include border-radius(50%);
    z-index: 9999;
  }
}

//custom document scroll
.custom-document-list {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 400px);
}

//category background
.category-background {
  border-radius: 5%;
  background-color: $folderBgColor;
}

//category horizontal scroll
.category-scroll {
  @include commonScrollbarBar;
  overflow-x: auto;
}

.request-scroll {
  @include commonScrollbarBar;
  overflow-y: auto;
  height: calc(100vh - 400px);
}

.tabListScroll {
  @extend .request-scroll;
  height: calc(100vh - 283px);
}

//User View OF Documents
.prent-section {
  .user-view-document {
    .MuiTabs-root {
      padding: 1.5rem 1.5rem 0rem 1.5rem !important;

      .MuiTabs-flexContainer {
        .MuiButtonBase-root {
          @extend .text-medium;
          @extend .font-weight-semibold;
        }
      }
    }

    .customDivider {
      margin-top: 0rem !important;
    }
  }
}

//logo sizes
.trellis-logo {
  width: 10rem;
}

.branding-logo {
  width: 7rem;
}

//profile refactor
.approveStatus {
  right: 32px;
  top: 16px;
}

.add-address-section {
  @extend .p-absolute;
  right: 4px;
  top: -14px;
}

.add-email-section {
  @extend .p-absolute;
  right: 4px;
  top: 48px;
}

.email-detect-section {
  width: 9.625rem;
  height: 6.5rem;

  .icon-new-email-detect {
    @extend .p-absolute;
    top: 38px;
    left: 32%;
  }
}

//file copy icon style
.icon-file {
  height: 42px;
  width: 48px;
}

//capture Image
.captureImagePlaceholder {
  width: 16rem;
  height: 7.5rem;
  @extend .p-absolute;
  top: 13px;
  left: -13%;
}

//video capture
.smapleImageBox {
  @extend .p-relative;
  width: 12.563rem;
  height: 9.375rem;

  span {
    font-size: 1.5rem;
    @include font-weight-semibold;

    &.left-corner {
      left: -1px;
      top: -2px;
      @extend .p-absolute;
    }

    &.right-corner {
      right: 0px;
      top: -3px;
      transform: rotate(90deg);
      @extend .p-absolute;
    }

    &.bottom-corner {
      top: 7.875rem;
      right: -2px;
      transform: rotate(180deg);
      @extend .p-absolute;
    }

    &.top-corner {
      top: 8.063rem;
      left: 0%;
      transform: rotate(270deg);
      @extend .p-absolute;
    }
  }

  .redMarkBox {
    height: 24px;
    width: 24px;
    border: 6px solid $state-denied;
    @include border-radius(50%);
    @extend .p-absolute;
    top: 14px;
    right: 15px;
  }

  &.uploadBox {
    background-color: $profile-overlay-bg;
    z-index: 1;
    @include border-radius(7px);

    .captureImagePlaceholder {
      opacity: 0.5;
    }

    .icon-play {
      @extend .p-absolute;
      color: $white;
      font-size: 2.5rem;
      left: 41%;
      top: 36%;
    }
  }
}

.videoPlaceholder {
  .captureImagePlaceholder {
    left: 1% !important;
  }
}

.tipsBox {
  .tipsList {
    @extend .p-relative;
    padding: 2px 13px;
    @extend .text-medium;
    @extend .textColor-200;

    &::before {
      @extend .p-absolute;
      content: '';
      height: 2px;
      width: 2px;
      background-color: $textColor-300;
      top: 10px;
      left: 5px;
      @include border-radius(50%);
    }
  }
}

//Scroll for preview file container
.text-break-word {
  word-break: break-word !important;
}

//lines trucation
.text-truncate-lines {
  display: -webkit-box;
  max-width: auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//Scroll for preview file container
.previewFileContainer {
  overflow-x: auto;
  @include commonScrollbarBar;
}

//bio refresh section
.custom-width-refresh-text {
  width: 8rem;
}

.refresh-icon-bg {
  width: 1.938rem;
  height: 1.938rem;
  @include border-radius(50%);
  background-color: $backgroundColor;
}

//word tag
.bio-verification-word-text {
  @extend .text-medium;
  @include flex-basic-center;
  @extend .textColor-200;
  padding: 0.375rem 1rem;
  @include border-radius(30px);
  background-color: $borderColor;
  min-width: 7rem;
  height: 2rem;
}

.cust-icon-size {
  font-size: 1rem !important;
}

.bottomFooter {
  .bottomBorderHide {
    display: none;
  }

  .bottomButtonControls {
    margin-bottom: 0.875rem;
  }
}

.msgCountBox {
  @extend .p-absolute;
  right: 0px;
  top: 20px;
}

.textReplyArea {
  textarea {
    height: 50px !important;
    overflow-y: auto !important;
    @include commonScrollbarBar;

    &.MuiInputBase-inputMultiline {
      padding-right: 4.25rem !important;
    }
  }
}

.messageListDailog {
  .modalHeader {
    @extend .p-relative;

    button {
      @extend .p-absolute;
      top: 4px;
    }
  }
}

//GPlacess Textfield
.gplacesTextBox {
  .MuiFormControl-root {
    &.MuiInputLabel-root {
      &.Mui-focused {
        transform: translate(16px, 12px) scale(0.65) !important;
      }

      &.MuiInputLabel-shrink {
        transform: translate(16px, 20px) scale(0.95);
      }
    }
  }
}

.documentTabData {
  .borderHiddenForDocument {
    border-color: transparent !important;
  }
}

//multi browser support btn sticky
.sticky-button {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  bottom: 3rem;
}

//for accreditation icon
.accreditation-icon-container-size {
  @include border-radius(50%);
  height: 5rem;
  width: 5rem;
}

//for accreditation icon
.accreditation-icon-container-size {
  @include border-radius(50%);
  height: 5rem;
  width: 5rem;
}

.checkboxWithImageSelect {
  @extend .p-absolute;
  right: 0px;
  top: -9px;
  background-color: $white;
}

.taxIdSearchCard {
  min-height: 27.625rem;
}

.statusBackground {
  background-color: $backgroundColor;
  @include border-radius(10px);
}

.taxIdDetailCard {
  min-height: 52.75rem;
}

.requestBackground {
  background-color: $backgroundColor !important;
  @include border-radius(4px);
}

.declineRequestBackground {
  background-color: $backgroundColor !important;
  // opacity: 0.5;
  @include border-radius(4px);
}

.listWrapperMin {
  height: calc(100vh - 290px);
  overflow: auto !important;
  @include commonScrollbarBar;
}

.listWrapperMax {
  @extend .listWrapperMin;
  height: calc(100vh - 475px);
}

.entitiyRequestCard {
  align-items: flex-start;
}

.documentCategoryList {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 235px);
}
.customInfoBox {
  @include border-radius(10px);
}

.verificationItem {
  background: $borderColor;
  border: 1px solid $backgroundColor;
  @include border-radius(30px);
  width: 7rem;
}

.imgPreview {
  @include border-radius(4px);
  max-width: 60%;
}

.customInfoBox {
  @include border-radius(10px);
}

.verificationItem {
  background: $borderColor;
  border: 1px solid $backgroundColor;
  @include border-radius(30px);
  width: 7rem;
}

.imgPreview {
  @include border-radius(4px);
  max-width: 60%;
}

.entityListFloating {
  bottom: 5.2rem !important;
}

.entityInviteDetailsPosition {
  @extend .flex-column-start;
  align-items: flex-start !important;
}

.categoryListContainer {
  @include commonScrollbarBar;
  height: 240px;
  overflow-y: auto;
}

.entityTextAlign {
  text-align: initial !important;
}

.stickyFooterActions {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  bottom: 3rem;
  background-color: $white;
  border: 1px solid $borderColor;
  @include border-radius(10px);
}

.cardHeight {
  min-height: 100vh;
}

.stickyBoxHeight {
  height: 200%;
}

.addEntityMsgRedius {
  @include border-radius(10px);
}

.addressTypeContainer {
  background: $primary-100;
  border: 1px solid $backgroundColor;
  @include border-radius(16px);
  width: 6.938rem;
}

.entityBadge {
  padding: 2px 12px !important;
  @extend .text-small;
  @extend .font-weight-medium;
  @include border-radius(40px);
}

.textEllipsis {
  @extend .text-ellipsis;
  max-width: 80%;
}

.profileRequirementContainer {
  background: $backgroundColor;
  @include border-radius(6px);
  width: 100%;
}

.entityInviteLabelRedius {
  @include border-radius(40px);
}

#scrollableDiv {
  #pandaDocIFrame {
    height: 100vh !important;
  }
}

.switchIconPosition {
  right: 0;
  top: 5px;
}

.messageListBox {
  height: calc(100vh - $message-list-box-height);
  overflow-y: auto;
  @include commonScrollbarBar;
  @media only screen and (max-width: 768px) {
    height: calc(100vh - $message-list-box-mobile-height);
  }
}

//mobile specific classes
.shareTabMobile {
  padding-top: 4px;
  @extend .icon-size-20;
}

.overflow-hidden {
  overflow: hidden;
}
.textHiddenWidth {
  width: 13.125em;
}

.folderNameHiddenWidth {
  width: 7.125em;
}

.beneficialUsersCardHeight {
  height: calc(100vh - 100px);
}

.beneficialUsersList {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 200px);
}

.notificationCount {
  @extend .text-medium;
  @extend .font-weight-semibold;
  @include border-radius(50px);
}

//notification indicator
.notificationIndicator {
  @extend .p-relative;
  span.icon-with-dot-notification {
    &::after {
      @extend .p-absolute;
      content: '';
      height: 8px;
      width: 8px;
      bottom: 44px;
      left: 49px;
      @include border-radius(50%);
    }
  }
}

//merchant logo
.merchantLogo {
  height: 3.75rem;
  width: 3.75rem;
}

.addProfileImgBtn {
  @include border-radius(50% !important);
  height: 32px;
  width: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  bottom: 36px;
  right: -94px;
}

.profileSummaryScreen {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 100px);
}

.addressVerificationDetailScreen {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 105px);
}

.shareIdProcessDetailScreen {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 105px);
}

.editEntityDetailsIcon {
  padding-top: 2px;
  top: 7px;
  right: 0px;
  font-size: 20px;
  @extend .cursorPointer;
}

.entityDetailsSection {
  @extend .text-ellipsis;
  max-width: 16rem;
}

.applicationMessageDetailsCard {
  height: calc(100vh - 90px);
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 140px);
  }
}

.userReplyBox {
  bottom: 20px;
  min-width: 86%;
}

.systemTabNotifications {
  height: calc(100vh - 325px);
}

.merchantTabNotifications {
  height: calc(100vh - 400px);
}

.customInputField {
  .MuiFormControl-root {
    width: 100% !important;
  }
  .MuiOutlinedInput-root {
    width: 100% !important;
  }
}

.memberAllReadyExist {
  @include border-radius(6px);
  background-color: $errorMemberAlreadyExistBgColor;
}

.memberAllreadyExistErrorIcon {
  @include border-radius(50%);
  border: 1px solid $state-denied;
  width: 32px;
  height: 32px;
  background-color: $white;
}

.boxCardMember {
  @include border-radius(10px);
  border: 1px solid $textColor-500;
}

.hideMemberMenu {
  display: none !important;
}

.notifiacationMerchantLogo {
  border-radius: 50%;
  height: 37px;
  width: 37px;
}

.memberTCardHeight {
  height: calc(100vh - 90px);
}

.boxCardListScroll {
  @include commonScrollbarBar;
  overflow: auto;
  height: calc(100vh - 317px);
}

.acceptRequestsScroll {
  height: calc(100vh - 290px);
  @include commonScrollbarBar;
  overflow: auto;
}

.positionAddMoreBtn {
  right: 0;
}

.entityAssociationCard {
  height: calc(100vh - 100px);
  overflow-y: auto;
  @include commonScrollbarBar;

  //To hide the scrollbars, but still be able to keep scrolling on all browsers
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

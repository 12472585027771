//Basier Circle
@font-face {
  font-family: 'Basier Circle';
  src: url('./fonts-text/basier_circle/BasierCircle-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('./fonts-text/basier_circle/BasierCircle-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('./fonts-text/basier_circle/BasierCircle-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('./fonts-text/basier_circle/BasierCircle-Bold.ttf');
  font-weight: 700;
}

.MuiTabs-root {
  &.MuiTabs-vertical {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        margin-right: 0px !important;
      }
    }
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      @extend .text-large;
      @extend .font-weight-semibold;
      color: $gray-shade-500;
      padding: 0px !important;
      margin-right: 40px;
      min-width: auto;
    }
  }

  .MuiTabs-indicator {
    bottom: 10px;
  }
}

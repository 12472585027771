.MuiChip-root {
  padding: 6px 16px !important;
  @include border-radius(30px);
  border: 1px solid $textColor-300;

  .MuiChip-label {
    @extend .font-weight-regular;
    @extend .text-medium;
    padding: 6px 0px !important;
    margin-right: 11px;
  }

  .MuiChip-deleteIcon {
    margin: 0px 0px 0 0px !important;
    font-size: $fontSize !important;
    color: $textColor-300 !important;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?2m5hvi');
  src: url('./fonts/icomoon.eot?2m5hvi#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?2m5hvi') format('truetype'), url('./fonts/icomoon.woff?2m5hvi') format('woff'),
    url('./fonts/icomoon.svg?2m5hvi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-camera:before {
  content: '\e928';
}
.icon-re-capture:before {
  content: '\e929';
}
.icon-switch-camera:before {
  content: '\e92a';
}
.icon-w8-w9-error:before {
  content: '\e922';
}
.icon-w8-w9-success:before {
  content: '\e923';
}
.icon-w8-w9-normal:before {
  content: '\e924';
}
.icon-wet-signature-error:before {
  content: '\e925';
}
.icon-wet-signature-success:before {
  content: '\e926';
}
.icon-wet-signature:before {
  content: '\e927';
}
.icon-address-error:before {
  content: '\e910';
}
.icon-address-success:before {
  content: '\e911';
}
.icon-address:before {
  content: '\e912';
}
.icon-biometric-error:before {
  content: '\e913';
}
.icon-biometric-success:before {
  content: '\e914';
}
.icon-biometric:before {
  content: '\e915';
}
.icon-identity-document-error:before {
  content: '\e916';
}
.icon-identity-document-success:before {
  content: '\e917';
}
.icon-identity-document:before {
  content: '\e918';
}
.icon-kyc-aml-error:before {
  content: '\e919';
}
.icon-kyc-aml-success:before {
  content: '\e91a';
}
.icon-kyc-aml:before {
  content: '\e91b';
}
.icon-profile-error:before {
  content: '\e91c';
}
.icon-profile-success:before {
  content: '\e91d';
}
.icon-Profile:before {
  content: '\e91e';
}
.icon-tax-id-error:before {
  content: '\e91f';
}
.icon-tax-id-success:before {
  content: '\e920';
}
.icon-tax-id:before {
  content: '\e921';
}
.icon-caution:before {
  content: '\e90e';
}

.icon-user:before {
  content: '\e90f';
}

.icon-broken-link:before {
  content: '\e90c';
}
.icon-email:before {
  content: '\e90d';
}
.icon-delete-outlined:before {
  content: '\e909';
}
.icon-edit-outlined:before {
  content: '\e90a';
}
.icon-user-profile:before {
  content: '\e90b';
}
.icon-text-box-plus:before {
  content: '\e906';
}
.icon-card-account-details:before {
  content: '\e907';
}
.icon-suitcase:before {
  content: '\e908';
}
.icon-SSN-Verification:before {
  content: '\e901';
}
.icon-Document-Verification:before {
  content: '\e900';
}
.icon-Biometric-Verification:before {
  content: '\e902';
}
.icon-Address-Verification:before {
  content: '\e903';
}
.icon-Signature-Verification:before {
  content: '\e904';
}
.icon-Supplemental-Documents:before {
  content: '\e905';
}

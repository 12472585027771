.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    padding: 10px !important;
    color: $textColor;
    @extend .text-medium;
    background: $white;
    border: 1px solid;
    @include border-radius(8px);

    .MuiTooltip-arrow {
      &::before {
        border: 1px solid;
        background: $white !important;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  &[data-popper-placement*='bottom'] {
    .MuiTooltip-arrow {
      width: 25px !important;
      height: 16px !important;
      margin-left: 3px !important;
      top: -6px !important;
    }
  }

  &[data-popper-placement*='top'] {
    .MuiTooltip-arrow {
      width: 25px !important;
      height: 19px !important;
      margin-left: 3px !important;
      top: 39px !important;
    }
  }

  &[data-popper-placement*='left'] {
    .MuiTooltip-arrow {
      width: 13px !important;
      height: 23px !important;
      right: -3px !important;
      top: 4px !important;
    }
  }

  &[data-popper-placement*='right'] {
    .MuiTooltip-arrow {
      width: 13px !important;
      height: 23px !important;
      left: -3px !important;
      top: 0px !important;
    }
  }
}

//tooltip placement with style
// .MuiTooltip-popper[data-popper-placement*="bottom"] {
//     .MuiTooltip-arrow {
//         width: 25px !important;
//         height: 19px !important;
//         margin-left: 0px !important;
//         top: -9px !important;
//     }
// }

.MuiTable-root {
  .MuiTableHead-root {
    tr {
      th {
        &.MuiTableCell-root {
          @extend .text-medium;
          padding: 8px 16px 8px 0px !important;
          color: $gray-shade-500 !important;
        }
      }
    }

    .MuiTableCell-head {
      .MuiButton-root {
        @extend .text-medium;
        color: $gray-shade-500 !important;
        margin-left: -5px;
        padding-left: 4px !important;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding-left: 0px;
        border-bottom: 1px solid $textColor-500 !important;
      }
    }
  }
}

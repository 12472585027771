//golbal toast scss
.toastContainer {
  //success
  .Toastify__toast--success {
    background-color: $state-approved !important;
  }

  //error
  .Toastify__toast--error {
    background-color: $state-denied !important;
  }

  //needs review
  .Toastify__toast--info {
    background-color: $state-review-need !important;
  }
}

.customLoader {
  .innerLoader {
    width: 60px;
    height: 60px;
    border: 3px solid $white;
    @include border-radius(50%);
    display: inline-block;
    @extend .p-relative;
    animation: rotation 1s linear infinite;

    &::after {
      content: '';
      @extend .p-absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px - 8px;
      height: 60px - 8px;
      @include border-radius(50%);
      border: 5px solid transparent;
    }
    /* keyFrames */
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

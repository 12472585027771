.tabListStyle {
  .MuiTabs-scroller {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        text-transform: capitalize;
        margin: 0px 15px !important;
      }
      justify-content: center;
    }
  }
}

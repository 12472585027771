.MuiTableFooter-root {
  .MuiTableRow-footer {
    .MuiBox-root {
      align-items: center;

      .MuiBox-root {
        @extend .text-medium;
        @extend .font-weight-regular;
        color: $textColor-200;
      }

      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          padding-right: 15px;

          .MuiOutlinedInput-input {
            padding-right: 18px;
          }

          input,
          fieldset {
            border: none !important;
          }
        }
      }
    }
  }

  .MuiPagination-root {
    margin-bottom: 6px;

    .MuiPagination-ul {
      li {
        &:last-child {
          .MuiButtonBase-root {
            margin-right: 0px !important;
          }
        }
      }

      .MuiPaginationItem-ellipsis {
        color: $textColor-400;
      }

      .MuiButtonBase-root {
        min-width: 40px;
        height: 40px;
        @extend .text-medium;
        font-weight: 600 !important;
        background: $white;
        margin-right: 10px;
        @include border-radius(4px);

        &.Mui-selected {
          color: $white !important;
        }

        &.Mui-disabled {
          opacity: 1;
          color: $white !important;
          background: $paginationButtonBgDisabledColor !important;
        }
      }
    }
  }
}

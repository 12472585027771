.MuiCalendarPicker-root {
  @include border-radius(12px);

  .PrivatePickersFadeTransitionGroup-root {
    @extend .text-large;
    color: $textColor-300;
  }

  .MuiTypography-caption {
    @extend .text-medium;
    @extend .font-weight-regular;
    color: $textColor-300;
  }

  .MuiButtonBase-root {
    &.Mui-disabled {
      color: $textColor-400 !important;
    }
  }

  .PrivatePickersSlideTransition-root {
    .MuiPickersDay-root {
      @extend .text-medium;
      @extend .font-weight-regular;
      color: $textColor-200;
      width: 35px;
      height: 35px;

      &:hover {
        @include border-radius(8px);
      }

      &.Mui-selected {
        color: $white;
        @extend .font-weight-semibold;
        @include border-radius(8px);
      }
    }

    .MuiPickersDay-today {
      @include border-radius(8px);
      border-color: $textColor-500;
    }
  }

  .PrivatePickersYear-yearButton {
    @extend .text-medium;
    @extend .font-weight-regular;
    color: $textColor-200;

    &:hover {
      @include border-radius(8px);
    }

    &.Mui-selected {
      color: $white;
      @extend .font-weight-semibold;
      @include border-radius(8px);
    }
  }

  .PrivatePickersMonth-root {
    width: 50px;
    height: 35px;
    @extend .PrivatePickersYear-yearButton;

    &:hover {
      @include border-radius(8px);
    }
  }
}

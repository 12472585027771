.MuiDialog-paper {
  background: $white;
  border: 1px solid $borderColor;
  box-shadow: $modal-box-shadow;
  border-radius: 10px !important;

  .iconWithTitle {
    svg {
      &.MuiSvgIcon-root {
        top: -11px;
        left: 20px;
        @extend .p-absolute;
      }
    }
  }

  .MuiDialogContent-root {
    @include commonScrollbarBar;
    padding: 0px 40px !important;
    margin: 0px !important;
  }

  .MuiBox-root {
    @extend .p-relative;

    .MuiDialogTitle-root {
      width: 100%;
      padding: 40px 40px 20px 40px;
    }
  }

  .MuiDialogActions-root {
    justify-content: end !important;

    .MuiButton-root {
      width: 140px !important;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
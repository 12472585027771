.MuiFormControl-root {
  &.MuiTextField-root {
    .MuiInputLabel-root {
      @extend .text-medium;
      color: $textColor;
      transform: translate(16px, 20px) scale(1);

      &.MuiInputLabel-shrink {
        -webkit-transform: translate(16px, 12px) scale(0.65);
        -moz-transform: translate(16px, 12px) scale(0.65);
        -ms-transform: translate(16px, 12px) scale(0.65);
        transform: translate(16px, 12px) scale(0.65);
      }
    }

    .MuiOutlinedInput-root {
      &.Mui-error {
        background-color: $inputErrorBgColor;
        .MuiOutlinedInput-input {
          background-color: $inputErrorBgColor;
        }
      }
      .MuiOutlinedInput-input.MuiInputBase-input {
        color: $textColor-200;
        @extend .text-medium;
        @extend .font-weight-medium;
      }

      .MuiOutlinedInput-notchedOutline {
        background-color: $inputBgColor;
        border-color: $borderColor;
        top: 0px;
      }

      .MuiInputAdornment-root {
        &.MuiInputAdornment-positionStart + input {
          padding: 9px 0px 9px 0px;
          color: $textColor-200;
          @extend .text-small;
          @extend .font-weight-medium;
        }

        &.MuiInputAdornment-positionStart {
          .MuiSvgIcon-root {
            color: $textColor-400;
          }
        }

        &.MuiInputAdornment-positionEnd {
          .MuiButton-root {
            all: unset;
            cursor: pointer;
            padding: 10px 0px !important;
            border: none !important;

            .MuiButton-startIcon {
              margin: 0px;
              color: $textColor-400;

              svg {
                font-size: 20px;
                @extend .p-absolute;
                top: 9px;
                right: 11px;
              }

              .icon-filters {
                padding-top: 4px;
                @extend .p-absolute;
              }
            }
          }
        }
      }
    }
  }

  //error msg text
  .MuiFormHelperText-root {
    &.Mui-error {
      margin: 5px 1px;
      @extend .text-small;
    }
  }
}

//MuiAutocomplete textfield
.MuiAutocomplete-root {
  &.MuiTextField-root {
    .MuiInputLabel-root {
      @extend .text-medium;

      &.MuiInputLabel-shrink {
        -webkit-transform: translate(16px, 8px) scale(0.85) !important;
        -moz-transform: translate(16px, 8px) scale(0.85) !important;
        -ms-transform: translate(16px, 8px) scale(0.85) !important;
        transform: translate(16px, 8px) scale(0.85) !important;
      }
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input.MuiInputBase-input {
        @extend .text-medium;
      }
    }
  }
}

//MuiAutocomplete-popper
.MuiAutocomplete-popper {
  box-shadow: none !important;

  .MuiAutocomplete-listbox {
    @include commonScrollbarBar;
    padding: 0px 0;

    li {
      @extend .text-medium;
      line-height: 18px !important;
      padding: 10px 16px !important;
      color: $textColor-300;

      &.Mui-focused {
        @extend .font-weight-bold;
      }

      .MuiCheckbox-root {
        padding: 0px !important;
        margin-right: 12px;
      }
    }
  }
}

// Variables

/******* Fonts *******/
$font-family-base: 'Basier Circle';

//font for icon
$font-family-icomoon: 'icomoon';

$fontSize: 1rem; //16px base font size

/******* Colors *******/
$primary: #163854;
$primary-100: #deeffb;
$primary-200: #f4fafe;
$primary-300: #f7fafd;

$balck: #18243d;
$white: #ffffff;

/******* Text and Icon Colors *******/
$textColor: #262625;
$iconColor: #4f6981;

//old
$textColor-100: #333333;
$textColor-200: #262625;
$textColor-300: #262625;
$textColor-400: #262625;
$textColor-500: #e0e0e0;
$textColor-600: #d9d9d9;

/******* State Colors *******/
$state-not-verified: #a8a8a8;
$state-approved: #2d9928;
$state-review-need: #dc975c;
$state-denied: #d63649;
$state-in-porgress: #668fc4;

/******* Gray Shades Colors *******/
$gray-shade-100: #0c0c0b;
$gray-shade-200: #252625;
$gray-shade-300: #40403f;
$gray-shade-400: #5a5a59;
$gray-shade-500: #747473;
$gray-shade-600: #a8a8a8;
$gray-shade-700: #dcdcdc;
$gray-shade-800: #ebebeb;
$gray-shade-900: #f6f6f6;

/******* Buttons Colors *******/
$buttonColorPrimary: #163854;
$buttonTextDisabledColor: #a8a8a8;
$buttonDisabledBgColor: #dcdcdc;

//old
$buttonGradientStrokeColor: #69819b;

/******* Border Colors *******/
$borderLightShade: #dbeaf8;
$borderDarkShade: #c6d3f2;

//old
$borderColor: #e0e7ff;

/******* Background Colors *******/
$bgLightBuleColor: #f3f4f8;
$bgLightDarkColor: #e7eaf5;
$backgroundColor: #edf2f3;
$inputErrorBgColor: #ffe4e4;
$inputBgColor: rgba(224, 231, 255, 0.2);
$buttonDisabledTextColor: rgba(46, 56, 77, 0.7);
$paginationButtonBgDisabledColor: rgba(105, 129, 155, 0.4);

/******* Background Linear *******/
$backGroundLinear: linear-gradient(180deg, $white 0%, $backgroundColor 100%);

/******* Tags Colors *******/
$tagColor100: #fff8e7;
$tagColor200: #f9faff;
$tagColorStroke100: #ffb700;
$tagColorStroke200: #becdff;

//Modal
$modal-box-shadow: 0px 25px 50px rgba(163, 165, 174, 0.12);
$iconDisabledColor: #d9d9d9;

//overlay bg
$profile-overlay-bg: rgba(0, 0, 0, 0.32);

//Allready existing member of entity error
$errorMemberAlreadyExistBgColor: rgba(235, 87, 87, 0.1);

//default error color
$defaultErrorColor: #f10808;

//disabled colors
$buttonPrimaryDisabledColor: rgba(46, 56, 77, 0.5);
$buttonWithIconBgDisabledColor: rgba(105, 129, 155, 0.4);

//scroll bg color
$scrollBgColor: rgba(105, 129, 155, 0.5);

//profile section
$profileSectionBgColor: rgba(105, 129, 155, 0.2);
$profileSectionBoxShadow: rgba(16, 35, 103, 0.2);

.multipleFileUpload {
  .MuiPaper-root {
    padding: 25px;
    border: 1px dashed $textColor-500;
    @include border-radius(4px);
    @include flex-basic-center;

    .MuiBox-root {
      display: flex;
      flex-direction: column;

      .uploadText {
        .MuiBox-root {
          @extend .text-medium;
          color: $textColor-200;
          text-align: center;
        }
      }
    }
  }

  .fileTypeWarning {
    text-align: center;
    padding: 10px;
  }
}

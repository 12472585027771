@font-face {
  font-family: $font-family-icomoon;
  src: url('fonts-icons/icomoon.eot?35bp39');
  src: url('fonts-icons/icomoon.eot?35bp39#iefix') format('embedded-opentype'),
    url('fonts-icons/icomoon.ttf?35bp39') format('truetype'), url('fonts-icons/icomoon.woff?35bp39') format('woff'),
    url('fonts-icons/icomoon.svg?35bp39#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//always add new icon in the top
//always remove colors while adding new icons

.icon-reset:before {
  content: '\e99d';
}

.icon-plus-fill-circle:before {
  content: '\e99c';
}

.icon-user-profile:before {
  content: '\e99b';
}

.icon-back-arrow:before {
  content: '\e993';
}

.icon-notes-edit:before {
  content: '\e995';
}

.icon-manage-entities:before {
  content: '\e996';
}

.icon-no-notes:before {
  content: '\e997';
}

.icon-notes-add:before {
  content: '\e998';
}

.icon-email-details:before {
  content: '\e990';
}

.icon-stake:before {
  content: '\e98f';
}

.icon-document-attached:before {
  content: '\e98e';
}

.icon-merchant-notification:before {
  content: '\e98d';
}

.icon-calendar-svg:before {
  content: '\e98c';
}

.icon-ownership:before {
  content: '\e98b';
}

.icon-owner-doc-failed:before {
  content: '\e985';
}

.icon-letter-of-good-failed:before {
  content: '\e986';
}

.icon-formation-doc-failed:before {
  content: '\e987';
}

.icon-letter-of-good-succes:before {
  content: '\e988';
}

.icon-formation-doc-succes:before {
  content: '\e989';
}

.icon-owner-doc-succes:before {
  content: '\e98a';
}

.icon-custom-document:before {
  content: '\e984';
}

.icon-accreditation-badge:before {
  content: '\e980';
}

.icon-qualified-institutional-buyer:before {
  content: '\e981';
}

.icon-qualified-purchaser:before {
  content: '\e982';
}

.icon-accredited:before {
  content: '\e983';
}

.icon-eye:before {
  content: '\e97f';
}

.icon-folder-open:before {
  content: '\e97e';
}

.icon-income-verification-success:before {
  content: '\e978';
}

.icon-income-verification-fail:before {
  content: '\e979';
}

.icon-accreditation:before {
  content: '\e97a';
}

.icon-income-verification:before {
  content: '\e97b';
}

.icon-accreditation-success:before {
  content: '\e97c';
}

.icon-accreditation-fail:before {
  content: '\e97d';
}

.icon-reconsider:before {
  content: '\e976';
}

.icon-record-video:before {
  content: '\e975';
}

.icon-curve-placeholder-:before {
  content: '\e977';
}

.icon-new-email-detect:before {
  content: '\e974';
}

.icon-attach:before {
  content: '\e973';
}

.icon-letter-evidence-success:before {
  content: '\e972';
}

.icon-empty-inbox:before {
  content: '\e971';
}

.icon-broken-link:before {
  content: '\e96d';
}

.icon-grid-view:before {
  content: '\e96f';
}

.icon-list-view:before {
  content: '\e970';
}

.icon-refresh:before {
  content: '\e96e';
}

.icon-profile-info:before {
  content: '\e96c';
}

.icon-download-files:before {
  content: '\e96b';
}

.icon-ideal-state:before {
  content: '\e969';
}

.icon-verify-but-notapprove:before {
  content: '\e96a';
}

.icon-dashboard:before {
  content: '\e965';
}

.icon-id-front-dl:before {
  content: '\e966';
}

.icon-id-back-dl:before {
  content: '\e967';
}

.icon-passport-success:before {
  content: '\e968';
}

.icon-w8-sign-verification-decline:before {
  content: '\e960';
}

.icon-w8-sign-verification:before {
  content: '\e962';
}

.icon-w8-w9:before {
  content: '\e963';
}

.icon-w8-w9-decline:before {
  content: '\e964';
}

.icon-profile-decline:before {
  content: '\e91b';
}

.icon-tax-id-decline:before {
  content: '\e93f';
}

.icon-identity-document-decline-2:before {
  content: '\e940';
}

.icon-id-back-decline-3:before {
  content: '\e94d';
}

.icon-irs-letter-evidencing-decline-4:before {
  content: '\e951';
}

.icon-kyc-aml-decline:before {
  content: '\e95b';
}

.icon-address-verification-decline:before {
  content: '\e95d';
}

.icon-id-front-decline:before {
  content: '\e95e';
}

.icon-biometric-verification-decline:before {
  content: '\e95f';
}

.icon-with-dot-notification:before {
  content: '\e99a';
}

.icon-share:before {
  content: '\e910';
}

.icon-expand-circle-down:before {
  content: '\e911';
}

.icon-documents-file:before {
  content: '\e921';
}

.icon-w8:before {
  content: '\e900';
}

.icon--form-filled:before {
  content: '\e901';
}

.icon-form-process:before {
  content: '\e902';
}

.icon-wet-signature:before {
  content: '\e903';
}

.icon-address:before {
  content: '\e904';
}

.icon-biometrics:before {
  content: '\e905';
}

.icon-id-back:before {
  content: '\e906';
}

.icon-id-front:before {
  content: '\e907';
}

.icon-id:before {
  content: '\e908';
}

.icon-kyc:before {
  content: '\e909';
}

.icon-profile:before {
  content: '\e90a';
}

.icon-tax-Id:before {
  content: '\e90b';
}

.icon-subtract:before {
  content: '\e90c';
}

.icon-union:before {
  content: '\e90d';
}

.icon-address-verified:before {
  content: '\e90f';
}

.icon-arrow-lefts:before {
  content: '\e912';
}

.icon-camera:before {
  content: '\e913';
}

.icon-communication:before {
  content: '\e994';
}

.icon-download-resend:before {
  content: '\e915';
}

.icon-file-copy:before {
  content: '\e916';
}

.icon-Frame-81:before {
  content: '\e917';
}

.icon-phone-link:before {
  content: '\e918';
}

.icon-notification .path1:before {
  content: '\e919';
}

.icon-notification .path2:before {
  content: '\e91a';
  margin-left: -1em;
}

.icon-drop-arrow:before {
  content: '\e91b';
}

.icon-play:before {
  content: '\e91c';
}

.icon-add-circle:before {
  content: '\e991';
}

.icon-add:before {
  content: '\e91e';
}

.icon-location:before {
  content: '\e91f';
}

.icon-approved:before {
  content: '\e920';
}

.icon-arrow-down-line:before {
  content: '\e922';
}

.icon-arrow-go-back:before {
  content: '\e923';
}

.icon-arrow-left-right:before {
  content: '\e924';
}

.icon-arrow-right:before {
  content: '\e925';
}

.icon-arrow-up:before {
  content: '\e926';
}

.icon-fing-biometrics:before {
  content: '\e927';
}

.icon-bookmark-fill:before {
  content: '\e928';
}

.icon-bookmark:before {
  content: '\e929';
}

.icon-briefcase:before {
  content: '\e992';
}

.icon-calendar:before {
  content: '\e92b';
}

.icon-camera-swap:before {
  content: '\e92c';
}

.icon-chat:before {
  content: '\e92d';
}

.icon-checkbox-circle:before {
  content: '\e92e';
}

.icon-checked:before {
  content: '\e92f';
}

.icon-close:before {
  content: '\e930';
}

.icon-delete:before {
  content: '\e931';
}

.icon-discussions-:before {
  content: '\e932';
}

.icon-document:before {
  content: '\e933';
}

.icon-edit:before {
  content: '\e934';
}

.icon-entity:before {
  content: '\e935';
}

.icon-error-warning:before {
  content: '\e936';
}

.icon-file:before {
  content: '\e937';
}

.icon-filter:before {
  content: '\e938';
}

.icon-flag:before {
  content: '\e939';
}

.icon-folder-add:before {
  content: '\e93a';
}

.icon-for-my-work:before {
  content: '\e93b';
}

.icon-forbid:before {
  content: '\e93c';
}

.icon-formation-document:before {
  content: '\e93d';
}

.icon-general-search:before {
  content: '\e93e';
}

.icon-Identity-document:before {
  content: '\e941';
}

.icon-image:before {
  content: '\e942';
}

.icon-user-kyc:before {
  content: '\e943';
}

.icon-letter-evidence:before {
  content: '\e944';
}

.icon-letter-of-good-standing:before {
  content: '\e945';
}

.icon-license:before {
  content: '\e946';
}

.icon-list:before {
  content: '\e947';
}

.icon-logout:before {
  content: '\e948';
}

.icon-mail-unread:before {
  content: '\e949';
}

.icon-members:before {
  content: '\e94a';
}

.icon-messages:before {
  content: '\e94b';
}

.icon-more-dots:before {
  content: '\e94c';
}

.icon-passport:before {
  content: '\e94e';
}

.icon-profile-users:before {
  content: '\e999';
}

.icon-record:before {
  content: '\e950';
}

.icon-review-needed:before {
  content: '\e952';
}

.icon-search:before {
  content: '\e953';
}

.icon-settings:before {
  content: '\e954';
}

.icon-statistics:before {
  content: '\e955';
}

.icon-Property-1subtract-line:before {
  content: '\e956';
}

.icon-tax-ID:before {
  content: '\e957';
}

.icon-toggle-line:before {
  content: '\e958';
}

.icon-upload:before {
  content: '\e959';
}

.icon-arrow-download:before {
  content: '\e95a';
}

.icon-Property:before {
  content: '\e95c';
}

.icon-lock:before {
  content: '\e961';
}

.progress-spinner {
  border: 3px solid $textColor-400;
  @include border-radius(50%);
  border-top: 3px solid $textColor-200;
  width: 2.188rem;
  height: 2.188rem;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;

  //spinner sizes
  &.sm-spinner {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  &.md-spinner {
    width: 1.563 !important;
    height: 1.563 !important;
  }

  &.lg-spinner {
    width: 2.188rem !important;
    height: 2.188rem !important;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@import 'fontFace.scss';
@import './variables';
@import './mixins';
@import './fontStyles.scss';
@import './components/button';
@import './components/pagination';
@import './components/tooltip';
@import './components/dropdown';
@import './components/chips';
@import './components/tab';
@import './components/calendar';
@import './components/checkbox_radio';
@import './components/input';
@import './components/multiple_file_upload';
@import './components/datatable';
@import './components/modal';
@import './components/toast';
@import './components/accordion';
@import './components/loader';
@import './components/progress-spinner';

//common style setup
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: $font-family-base;
  font-size: $fontSize;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundColor;
}

//Typography
.text-h1 {
  //font size 32px
  font: normal normal 2rem/54px $font-family-base;
}

.text-h2 {
  //font size 24px
  font: normal normal 1.5rem/36px $font-family-base;
}

.text-h3 {
  //font size 18px
  font: normal normal 1.125rem/28px $font-family-base;
}

.text-h4 {
  //font size 28px
  font: normal normal 1.75rem/42px $font-family-base;
}

.text-large {
  //font size 16px
  font: normal normal 1rem/24px $font-family-base;
}

.text-medium {
  //font size 14px
  font: normal normal 0.875rem/20px $font-family-base;
}

.text-small {
  //font size 12px
  font: normal normal 0.75rem/18px $font-family-base;
}

.text-extra-small {
  //font size 10px
  font: normal normal 0.625rem/16px $font-family-base;
}

.font-weight-bold {
  //font weight 700
  @include font-weight-bold();
}

.font-weight-semibold {
  //font weight 600
  @include font-weight-semibold();
}

.font-weight-medium {
  //font weight 500
  @include font-weight-medium();
}

.font-weight-regular {
  //font weight 400
  @include font-weight-regular();
}

//text icon colors
//new
.textColor {
  color: $textColor;
}
.iconColor {
  color: $iconColor;
}

//new
.textColor {
  color: $textColor;
}
.iconColor {
  color: $iconColor;
}

//old
.textColor-100 {
  color: $textColor-100;
}

.textColor-200 {
  color: $textColor-200;
}

.textColor-300 {
  color: $textColor-300;
}

.textColor-400 {
  color: $textColor-400;
}

.textColor-500 {
  color: $textColor-500;
}

.textColor-disabled {
  color: $textColor-600;
}

//common classes

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size
.font-size-77 {
  font-size: 77px;
}

.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.d-flex {
  display: flex;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}

//state color code
.font-italic {
  font-style: italic !important;
}

.error-color-state {
  color: $state-denied;
}

.success-color-state {
  color: $state-approved;
}

.warning-color-state {
  color: $state-review-need;
}

.inprogress-color-state {
  color: $state-in-porgress;
}

.showNone {
  display: none !important;
}

.height-100 {
  height: 100%;
}

//icon sizes
.icon-size-14 {
  font-size: 0.875rem;
}

.icon-size-16 {
  font-size: 1rem;
}

.icon-size-20 {
  font-size: 1.25rem;
}

.icon-size-24 {
  font-size: 1.5rem;
}

.icon-size-32 {
  font-size: 2rem !important;
}

.icon-size-60 {
  font-size: 3.75rem;
}

.icon-size-40 {
  font-size: 2.5rem !important;
}

.icon-size-72 {
  font-size: 4.5rem;
}

.icon-size-80 {
  font-size: 5rem !important;
}

.icon-size-92 {
  font-size: 5.75rem !important;
}

.icon-size-52 {
  font-size: 3.25rem !important;
}

.icon-size-180 {
  font-size: 11.25rem !important;
}

.circularIcon {
  @extend .p-absolute;
  height: 72px;
  width: 72px;
  top: 42px;
  left: 30%;
  @include border-radius(50%);
}

.readMoreTextContainer {
  display: inline;
  width: 100%;
}

//photo capture instrucation
.photoCaptureInstrucation {
  .photo-placeholder-box {
    height: 10rem;
    width: 12.5rem;

    span {
      font-size: 1.5rem;
      @include font-weight-semibold;

      &.left-corner {
        left: 0px;
        top: 0px;
        @extend .p-absolute;
      }

      &.right-corner {
        right: 0px;
        top: 0px;
        transform: rotate(90deg);
        @extend .p-absolute;
      }

      &.bottom-corner {
        top: 85%;
        right: 0%;
        transform: rotate(180deg);
        @extend .p-absolute;
      }

      &.top-corner {
        top: 87%;
        left: 0%;
        transform: rotate(270deg);
        @extend .p-absolute;
      }
    }

    .capture-image {
      max-width: 160px;
      max-height: 120px;
    }
    .upload-image {
      width: 214px;
      height: 165px;
    }
  }
}

.text-break-word {
  word-break: break-word !important;
}

// Dialog Title CSS for User Portal
.commonDialogStyle {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiBox-root {
        .MuiTypography-root {
          padding: 24px !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

//notifications listing clear button
.clearButton {
  position: sticky;
  bottom: 2px;
  width: 100%;
  background: $white;
  box-shadow: 0px 0px 10px $profile-overlay-bg;
}

//customBadge
.customBadge {
  background-color: $bgLightDarkColor;
  @include border-radius(16px);
  width: fit-content;
}

.commonNotificationScroll {
  @include commonScrollbarBar;
  overflow: auto;
}

.titleBorderStyle {
  position: relative;
  &::after {
    @extend .p-absolute;
    content: '';
    height: 36px;
    width: 6px;
    bottom: 0px;
    left: 0px;
    @include border-radius(4px);
  }
}

.previewFileContainer {
  overflow-x: auto;
  @include commonScrollbarBar;
}

.categoryBackground {
  @include border-radius(4px);
}

//blank component container
.blankComponentContainer {
  //hard coded color will be replaced once style guide is updated
  border: 1px dashed rgb(190, 205, 255);
  @include border-radius(8px);
}

.blankComponentIcon {
  height: 72px;
  width: 72px;
  background-color: rgba(105, 129, 155, 0.1);
  @include border-radius(50%);
}

//icon rotate
.leftArrowCollapse {
  transform: rotate(-180deg);
}

//file upload btn
.fileUploadBtn {
  @extend .text-medium;
  color: $white;
  padding: 9px 12px;
  @include border-radius(4px);
  @extend .text-center;
  min-width: 140px;
}

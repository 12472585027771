.navBarStyle {
  min-height: 88px;

  .MuiButtonBase-root {
    padding-top: 13px;
    @include border-radius(10px);

    &.Mui-selected {
      .MuiBottomNavigationAction-label {
        @extend .textColor-200;
      }
    }

    .MuiBottomNavigationAction-label {
      @extend .textColor-300;
      @extend .text-small;
      @extend .font-weight-semibold;
    }

    span[class*='icon-'] {
      font-size: 24px;
      @extend .textColor-200;
    }
  }
}

.toggleEntityIcon {
  padding-top: 2px;
  top: 7px;
  right: 0px;
  font-size: 20px;
  @extend .cursorPointer;
}

.dashboardlistItem {
  height: calc(100vh - 420px);
  overflow: auto;
  @include commonScrollbarBar;

  .idName {
    @include flex-basic-center;
    justify-content: start;
    padding-left: 4px;
    @extend .text-large;
    @extend .font-weight-medium;
    @extend .textColor-200;
  }

  span[class*='icon-'] {
    font-size: 24px;
    @extend .textColor-200;
  }

  .MuiGrid-item {
    @extend .text-large;
    @extend .font-weight-medium;
    @extend .textColor-200;
  }
}

//up silde icon
.upSildeArrow {
  font-size: 24px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  display: table;
}
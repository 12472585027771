.MuiButton-root {
  //font family remove later
  font-family: $font-family-base !important;
  line-height: 18px !important;
  padding: 10px 12px !important;
  @include border-radius(4px);
  @extend .text-medium;
  text-transform: none !important;
  height: 38px;
  &.button-small {
    height: 32px !important;
  }

  &.MuiButton-contained {
    color: $white;
    box-shadow: none;

    &.Mui-disabled {
      color: $buttonPrimaryDisabledColor !important;
      background-color: $buttonDisabledBgColor !important;
    }

    &:hover {
      box-shadow: none;
    }

    //button with text icon styled
    &.buttonWithTextIcon {
      &.Mui-disabled {
        color: $white !important;
        background-color: $buttonDisabledBgColor !important;
      }
    }
  }

  &.MuiButton-outlined {
    background: $white;
    border: 1px solid !important;
    min-width: auto !important;
    .MuiButton-startIcon {
      margin-right: 0px;
      margin-left: 0px;
    }
    &:hover {
      background-color: transparent !important;
    }
    &.Mui-disabled {
      color: $buttonDisabledBgColor !important;
      border-color: $buttonDisabledBgColor !important;
      .MuiButton-startIcon {
        .MuiSvgIcon-root {
          color: $textColor-400 !important;
        }
      }

      .actionIconFont-24 {
        color: $iconDisabledColor !important;
      }
    }

    //button with icon outline styled
    &.buttonWithTextIcon {
      &.Mui-disabled {
        color: $buttonDisabledBgColor !important;
        border-color: $buttonDisabledBgColor !important;
      }
    }
  }

  &.MuiButton-text {
    min-width: auto !important;

    .MuiButton-startIcon {
      margin-right: 0px;
      margin-left: 0px;
    }
    &:hover {
      background-color: transparent !important;
    }
    &.Mui-disabled {
      color: $buttonDisabledBgColor !important;
      .actionIconFont-24 {
        color: $iconDisabledColor !important;
      }
    }
  }

  //btn width varient
  &.button-w-240 {
    min-width: 240px !important;
  }

  &.button-w-140 {
    min-width: 140px !important;
  }

  &.button-w-80 {
    min-width: 80px !important;
  }
  //button with icon size
  .MuiButton-startIcon {
    div[class*='icon-'] {
      font-size: 16px !important;
    }
  }
}

//floating button

.floating-btn {
  position: sticky;
  z-index: 9999999;
  bottom: 0.2rem;
  width: 50px;
  height: 50px;
  background: $primary;
  @include border-radius(50%);
  color: $white;
  line-height: 65px;
  text-align: center;
  @extend .icon-size-24;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  @extend .cursorPointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  left: 86%;
}

.MuiCheckbox-root {
  color: $buttonGradientStrokeColor !important;

  .MuiSvgIcon-root {
    height: 24px;
    width: 24px;
  }

  &.Mui-disabled {
    color: $textColor-300 !important;
  }
}

.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: $textColor;
      @extend .text-medium;
      @extend .font-weight-medium;
    }
  }
}

.MuiCheckbox-root + span.MuiFormControlLabel-label {
  @extend .text-medium;
  @extend .font-weight-medium;
}

//radio btn
.MuiRadio-root {
  color: $buttonGradientStrokeColor !important;
}

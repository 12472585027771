.selectDropBox {
  .MuiInputLabel-root {
    padding: 11px 8px;
    color: $textColor;
    transform: translate(8px, 4px) scale(1);

    &.MuiInputLabel-shrink {
      -webkit-transform: translate(9px, -6px) scale(0.85);
      -moz-transform: translate(9px, -6px) scale(0.85);
      -ms-transform: translate(9px, -6px) scale(0.85);
      transform: translate(9px, -6px) scale(0.85);
    }
  }
  .MuiOutlinedInput-root {
    svg.MuiSvgIcon-root {
      color: $iconColor;
    }
    .MuiOutlinedInput-notchedOutline {
      background-color: $inputBgColor;
      border-color: $borderColor;
      &:hover {
        border-color: $borderColor;
      }
    }
  }
  span[class*='icon-'] {
    @extend .p-absolute;
    @extend .cursorPointer;
    right: 16px;
  }

  .Mui-disabled {
    .icon-arrow-down-line {
      color: $textColor-600;
    }
  }

  .MuiSelect-select {
    color: $textColor-200;
    @extend .text-medium;
  }
}

//select dropbox
.MuiList-root {
  padding: 0px !important;
  border: 1px solid transparent;
  @include border-radius(4px);

  .MuiMenuItem-root {
    color: $textColor-300;
    @extend .text-medium;
    padding: 10px 16px;

    &.Mui-selected {
      @extend .font-weight-bold;
    }
  }
}

//autocomplete
.customIcon {
  .MuiAutocomplete-clearIndicator {
    @extend .textColor-200;
    margin-top: -5px !important;
    margin-right: 5px;

    &:hover {
      background: transparent !important;
    }
  }

  .MuiAutocomplete-popupIndicator {
    &:hover {
      background: transparent !important;
    }

    margin-top: -4px !important;
    margin-right: 5px !important;
  }
}

.filterAutocompleteText {
  .MuiChip-root {
    top: 11px;
    left: -7px;

    .MuiChip-label {
      @extend .textColor-300;
    }
  }
}

//MuiAutocomplete-popper
.MuiAutocomplete-popper {
  .MuiPaper-root {
    &.MuiPaper-elevation {
      &.MuiPaper-rounded {
        border-radius: 4px !important;
      }
    }
  }
}

//Normal select box
.selectDropdownList {
  &.MuiPaper-root {
    &.MuiPaper-rounded {
      border-radius: 4px !important;
    }
  }
}
